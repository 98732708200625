import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { Info, Settings } from 'luxon';
import { action } from '@ember/object';

export default class Application extends Route {
  @service account;
  @service modalManager;
  @service session;
  @service store;
  @service router;
  @service intl;
  @service browser;
  @service electronCommunication;

  async beforeModel() {
    const locale = navigator.language || navigator.userLanguage || 'en';
    if (locale.indexOf('fr') !== -1) {
      this.intl.setLocale(['fr-fr']);
      tBoxClient.setLocale('fr');
      Settings.defaultLocale = 'fr';
      Info.defaultLocale = 'fr';
    } else {
      this.intl.setLocale(['en-us']);
      tBoxClient.setLocale('en');
      Settings.defaultLocale = 'en';
      Info.defaultLocale = 'en';
    }

    Info.defaultFormat = this.intl.t('luxonFormat');
    if (!this.browser.isSupportedBrowser) {
      return;
    }

    window.tabId = crypto.randomUUID();
    localStorage.setItem('notificationTabId', window.tabId);
    window.onunload = function() {
      if (localStorage.getItem('notificationTabId') === window.tabId) {
        localStorage.setItem('notificationTabId', 'none');
      }
    };
    window.addEventListener('storage', (event) => {
      switch ( event.key ) {
        case 'notificationTabId':
          if (event.newValue === 'none') {
            localStorage.setItem('notificationTabId', window.tabId);
          }
          break;
      }
    });

    await this.electronCommunication.cfsbSetup();
  }

  afterModel() {
    document.title = 'Cryptobox';
  }

  @action
  logout() {
    this.account.logout('user');
  }
}
